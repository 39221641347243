import { useLayoutEffect } from 'react'

import { gsap } from 'gsap'
import SplitText from '../SplitText'

import './hero-section.css'

function HeroSection() {
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const landingPageTl = gsap.timeline({
        delay: 0.5,
        defaults: {
          duration: 0.5,
          delay: 0.2,
          ease: "ease-in"
        }
      })

      landingPageTl
        .from(".main-header .company-info", {
          opacity: 0,
        })
        .from([
          ".main-header .toggle-theme-btn--mobile",
          ".main-nav__toggle-btn",
          ".main-nav__nav-link",
          ".main-header .toggle-theme-btn--desktop",
        ], {
          opacity: 0,
          yPercent: -100,
          duration: 0.3,
          stagger: 0.1,
        }, "<")
        .from(".hero-section__img", {
          xPercent: 50,
          opacity: 0,
        })
        .from(".hero-section__heading h2 > span, .hero-section__heading p", {
          clipPath: "inset(0 0 100% 0)",
          stagger: 0.05,
        })
        .from(".hero-section__content > .action-btn", {
          opacity: 0,
          yPercent: -50,
          duration: 0.75,
          delay: 0,
          ease: "back.inOut(2)",
        }, "-=0.3")
    })

    return () => ctx.revert()
  }, [])
  
  return (
    <div className="hero-section">
      <div className="hero-section__img-section">
        <div className="hero-section__img-container">
          <img src="/images/minohealth-platform-imac-img.png" alt="Minohealth platform" className="hero-section__img" />
        </div>
      </div>

      <div className="hero-section__content-section">
        <div className="hero-section__content">
          <div className="hero-section__heading">
            <h2>
              <SplitText 
                type="words"
              >DEMOCRATIZING QUALITY HEALTHCARE</SplitText>
            </h2>
            <p>Artificial intelligence powered solutions</p>
          </div>

          <a href="https://platform.minohealth.ai" target="_blank" rel="noreferrer" className="action-btn">Visit our AI platform</a>
        </div>
      </div>
    </div>
  )
}

export default HeroSection
