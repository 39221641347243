import { useState } from "react"
import { Link, Outlet, ScrollRestoration } from "react-router-dom"
import BlogNav from "./BlogNav"
import useWindowScrollDirection from "./useScrollDirection"
import useTheme from "../../hooks/useTheme"

import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
import { TbMoonStars } from 'react-icons/tb'
import { HiLightBulb } from 'react-icons/hi'

import "./blog.css"

export default function Blog() {
  const scrollDirection = useWindowScrollDirection()
  const shouldHideHeaderOnMobile = scrollDirection === "down"
  const [accentHsl, setAccentHsl] = useState({background: undefined, text: undefined})

  function setBlogAccentHsl(backgroundHsl, textHsl) {
    setAccentHsl({background: backgroundHsl, text: textHsl})
  }

  return (
    <div
      className="blog"
      style={{
        "--blog-accent-background-hsl": accentHsl.background,
        "--blog-accent-text-hsl": accentHsl.text,
      }}
    >
      <ScrollRestoration />

      <header className="blog__main-header" data-hide={shouldHideHeaderOnMobile}>
        <div className="blog__home-links">
          <Link to="/blog">minoHealth AI Labs Blog</Link>
          <Link to="/">
            <img src="/images/logos/minohealth-logo.png" alt="minoHealth" />
          </Link>
        </div>

        <BlogNav />
      </header>

      <main className="blog__body">
        <Outlet context={{setBlogAccentHsl}} />
      </main>

      <footer className="blog__main-footer">
        <div className="blog__newsletter">
          <p>Subscribe to our newsletter to learn about new product features, the latest in technology, solutions, and updates.</p>
          <form onSubmit={e => e.preventDefault()}>
            <input 
              type="email" 
              placeholder="Your email address"
              aria-label="Email address"
              name="email"
              required
            />
            <button type="submit">
              <HiOutlineArrowNarrowRight />
              <span>Subscribe</span>
            </button>
          </form>
        </div>

        <div className="blog__social-links">
          <span>&copy; 2023</span>
          <a href="https://twitter.com/minohealth" target="_blank" rel="noreferrer">Twitter</a>
          <a href="https://www.linkedin.com/company/minohealth/" target="_blank" rel="noreferrer">Linkedin</a>
          <a href="mailto:support@minohealth.org">Email</a>
        </div>

        <ThemeToggle />
      </footer>
    </div>
  )
}

function ThemeToggle() {
  const {isDarkTheme, toggleTheme} = useTheme()

  return (
    <button
      type="button"
      title="Dark theme toggle"
      className="blog-theme-toggle-btn"
      aria-pressed={isDarkTheme}
      onClick={toggleTheme}
    >
      {
        isDarkTheme
          ? <HiLightBulb className="icon-btn__icon" />
          : <TbMoonStars className="icon-btn__icon" />
      }
      <span className="sr-only">Dark theme</span>
    </button>
  )
}
