const posts = [
  {
    title: "minoHealth.ai version 1.9: Four Times Faster Diagnosis & Batch Diagnoses",
    slug: "minohealth-ai-version-1-9-four-times-faster-diagnosis-batch-diagnoses",
    date: "8th july, 2023",
    image: "https://miro.medium.com/v2/resize:fit:720/0*GEc6yPXEvnsg90L4",
    excerpt: "Link: platform.minohealth.ai. Release Name: Version 1.9. Purpose: This release introduces new features and gigantic performance improvements.",
    category: "research",
    author: "Software Engineering team",
  },
  {
    title: "minoHealth.ai Version 1.7: Multilingual Support And PDF Report Redesign",
    slug: "minohealth-ai-version-1-7-multilingual-support-and-pdf-report-redesign",
    date: "30th May, 2023",
    image: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*Oppku1J_-hk9zwXmjUcqcA.jpeg",
    excerpt: "Release Name: Version 1.7. Purpose: This release introduces new features, and bug fixes, security updates, and performance improvements.",
    category: "research",
    author: "Software Engineering team",
  },
  {
    title: "Introducing Oya.io: Our State-of-The-Art DICOM Conversion Tool",
    slug: "introducing-oya-io-our-state-of-the-art-dicom-conversion-tool",
    date: "March 15, 2023",
    image: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*T6BHlZXQan3AL6Q16Ar4cw.png",
    excerpt: "DICOM files play a crucial role in medical imaging, facilitating standardized communication and exchange of medical images and related information between different healthcare providers and systems.",
    category: "research",
    author: "Software Engineering team",
  },
  {
    title: "minoHealth.ai version 1.4.1: Launching AI for Chest Pre-Diagnosis via X-Rays",
    slug: "minohealth-ai-version-1-4-1-launching-ai-for-chest-pre-diagnosis-via-x-rays",
    date: "8th February, 2023",
    image: "https://miro.medium.com/v2/resize:fit:1400/0*AMHumyblO3l_xA7S",
    excerpt: "Towards our goal of democratizing quality healthcare, we built the minoHealth.ai platform. Since we launched in November 2022, we have been working to improve our Artificial Intelligence (AI) system and the web platform.",
    category: "research",
    author: "Machine Learning Engineering team, Software Engineering team",
  },
]

const CATEGORIES = ["news", "research"]

const postsLookup = Object.fromEntries(posts.map(post => [post.slug, post]))

export async function fetchPosts(category) {
  if(category == null) return posts
  if(!CATEGORIES.includes(category)) return null
  return posts.filter(post => post.category === category)
}

export async function fetchPost(slug) {
  try {
    const contentUrl = (await import(`./articles/${slug}.txt`)).default
    const content = await (await fetch(contentUrl)).text()
    const post = postsLookup[slug]
    return {...post, content}
  } catch(err) {
    return null
  }
}