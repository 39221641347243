import { createContext, useContext } from "react"
import { Link } from "react-router-dom"
import clsx from "../../utils/clsx"

import "./blog-post-card.css"

export default function BlogPostCard({ link, children, className, ...restOfProps }) {
  return (
    <article
      className={clsx("blog-post-card", className)}
      {...restOfProps}
    >
      <blogPostCardContext.Provider value={{link}}>
        {children}
      </blogPostCardContext.Provider>
    </article>
  )
}

BlogPostCard.Image = function BlogPostCardImage({ className, ...restOfProps }) {
  return <img className={clsx("blog-post-card__image", className)} {...restOfProps} />
}

BlogPostCard.Content = function BlogPostContent({ children, className, ...restOfProps }) {
  return (
    <div
      className={clsx("blog-post-card__content", className)}
      {...restOfProps}
    >{children}</div>
  ) 
}

BlogPostCard.Category = function BlogPostCategory({ children, className, ...restOfProps }) {
  return (
    <span
      className={clsx("blog-post-card__category", className)}
      {...restOfProps}
    >{children}</span>
  ) 
}

BlogPostCard.Title = function BlogPostCardTitle({ children, className, linkProps, ...restOfProps }) {
  const { link } = useContext(blogPostCardContext)
  
  return (
    <h2
      className={clsx("blog-post-card__title", className)}
      {...restOfProps}
    ><Link to={link} {...linkProps}>{children}</Link></h2>
  ) 
}

BlogPostCard.Excerpt = function BlogPostCardExcerpt({ children, className, ...restOfProps }) {
  return (
    <p
      className={clsx("blog-post-card__excerpt", className)}
      {...restOfProps}
    >{children}</p>
  ) 
}

const blogPostCardContext = createContext({link: ""})