import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import BlogPostCard from "./BlogPostCard";
import ServerRedirect from "../../components/routes/ServerRedirect";

import { IoIosSearch } from "react-icons/io";

import "./blog-posts.css"

export default function BlogHome({ category }) {
  const posts = useLoaderData()
  const title = typeof category === "string"
    ? category.slice(0, 1).toUpperCase() + category.slice(1)
    : "All posts"
  const [search, setSearch] = useState("")
  const filteredPosts = posts.filter(post => post.title.toLowerCase().includes(search.toLowerCase()))
  
  if(posts == null) return <ServerRedirect to="/404" />

  return (
    <section className="blog-posts">
      <div className="blog-posts__header">
        <h1>{ title }</h1>

        <form>
          <div className="blog-posts__search-field">
            <IoIosSearch />
            <input
              type="search"
              placeholder="Search topic"
              value={search}
              onChange={e => setSearch(e.target.value)}
              key={category ?? ""}
            />
          </div>
        </form>
      </div>

      <div className="blog-posts__post-list">
        {posts.length === 0
          ? (<p>No posts for this category</p>)
          : null
        }

        {posts.length !== 0 && filteredPosts.length === 0
          ? (<p>No posts found for this search</p>)
          : null
        }

        {filteredPosts.map(post => (
          <BlogPostCard link={post.slug} key={post.slug}>
            <BlogPostCard.Image src={post.image} />

            <BlogPostCard.Content>
              <BlogPostCard.Category>{post.category.toUpperCase()}</BlogPostCard.Category>
              <BlogPostCard.Title>{post.title}</BlogPostCard.Title>
              <BlogPostCard.Excerpt>{post.excerpt}</BlogPostCard.Excerpt>
            </BlogPostCard.Content>
          </BlogPostCard>  
        ))}
      </div>

    {/* <button
        className="blog-posts__load-more-btn"
      >LOAD MORE</button> */}
    </section>
  )
}
