import { useLayoutEffect, useRef } from 'react'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/all'
import { throttle } from '../../utils/rate-limiters'

import { BsInstagram, BsTwitter } from 'react-icons/bs'
import { ImFacebook, ImLinkedin2 } from 'react-icons/im'

import './footer.css'

gsap.registerPlugin(ScrollTrigger)

function Footer() {
  const headingRef = useRef(null)
  const collaboratorsRef = useRef(null)
  const footerRef = useRef(null)
  const bgImgRef = useRef(null)

  useLayoutEffect(() => {
    if(footerRef.current === null) return
    if(bgImgRef.current === null) return
    
    const ctx = gsap.context(() => {
      gsap.fromTo(bgImgRef.current, 
        {scale: 2},
        {
          scrollTrigger: {
            trigger: footerRef.current,
            start: "top bottom",
            scrub: 0.75,
          },
          scale: 1,
        }
      )
    })

    return () => ctx.revert()
  }, [bgImgRef, footerRef])

  useLayoutEffect(() => {
    if(footerRef.current === null) return
    if(collaboratorsRef.current === null) return

    const collaboratorImgs = [...collaboratorsRef.current.querySelectorAll("img")]
    let resizeObserver = null

    const ctx = gsap.context((self) => {
      gsap.from(headingRef.current, {
        scrollTrigger: {
          trigger: footerRef.current,
          start: "30% bottom",
        },
        xPercent: 10,
        opacity: 0,
        duration: 0.3,
        ease: "ease-out",
      })
      
      gsap.from(collaboratorsRef.current, {
        scrollTrigger: {
          trigger: footerRef.current,
          start: "30% bottom",
        },
        xPercent: 100,
        duration: 0.8,
      })

      const isImgsLoaded = collaboratorImgs.every(img => img.complete)
      if(!isImgsLoaded) {
        const imgLoadPromises = collaboratorImgs.map(img => new Promise(resolve => {
          img.addEventListener("load", resolve)
        }))
        Promise.all(imgLoadPromises).then(() => {
          setupCollaboratorsScrollWithResize()
        })
      }
      else {
        setupCollaboratorsScrollWithResize()
      }

      function setupCollaboratorsScrollWithResize() {
        setupCollaboratorsScroll()
        resizeObserver = new ResizeObserver(throttle(setupCollaboratorsScroll, 300))
        resizeObserver.observe(footerRef.current)
      }

      function setupCollaboratorsScroll() {
        const collaboratorsScrollWidth = getCollaboratorsScrollWidth()

        if(collaboratorsScrollWidth <= getCollaboratorsPadding()) {
          gsap.set(collaboratorsRef.current, {
            justifyContent: "center",
          })
          return
        }
        self.revert()
        gsap.to(collaboratorImgs, {
          scrollTrigger: {
            trigger: footerRef.current,
            start: "50% bottom",
            end: "+=50%",
            scrub: 0.5,
          },
          x: () => -getCollaboratorsScrollWidth(),
        })
        ScrollTrigger.refresh()
      }

      function getCollaboratorsScrollWidth() {
        return collaboratorsRef.current.scrollWidth - footerRef.current.clientWidth + getCollaboratorsPadding()
      }

      function getCollaboratorsPadding() {
        return parseFloat(getComputedStyle(collaboratorsRef.current).paddingLeft)
      }
    })

    return () => {
      ctx.revert()
      if(resizeObserver !== null) resizeObserver.disconnect()
    }
  }, [footerRef, collaboratorsRef])

  return (
    <footer className="main-footer" ref={footerRef}>
      <img src="/images/map.png" alt="World map" className="main-footer-bg-img" ref={bgImgRef} />

      <div className="collaborators-section">
        <h2 className="collaborators-section__heading" ref={headingRef}>COLLABORATORS</h2>
        <div 
          className="collaborators-section__content" 
          ref={collaboratorsRef}
        >
          <img src="/images/logos/imperial-college-logo-white.png" alt="Imperial College" className="collaborators-section__img" />
          <img src="/images/logos/euracare-logo-white.png" alt="Euracare" className="collaborators-section__img" />
          <img src="/images/logos/ukri-logo-white.png" alt="UK Research and Innovation" className="collaborators-section__img" />
          <img src="/images/logos/who-logo-white.png" alt="World Health Oraganization" className="collaborators-section__img" />
          <img src="/images/logos/itu-logo-white.png" alt="International Telecommunication Union" className="collaborators-section__img collaborators-section__img--large" />
          <img src="/images/logos/nvidia-inception-logo-white.png" alt="Nvidia inception program" className="collaborators-section__img" />
          <img src="/images/logos/ai-for-health-logo-white.png" alt="WHO AI for Health" className="collaborators-section__img" />
          <img src="/images/logos/bill-melinda-gates-foundation-logo-white.png" alt="Bill and Melinda Gates Foundation" className="collaborators-section__img" />
          <img src="/images/logos/meta-ai-logo-white.png" alt="Meta AI" className="collaborators-section__img" />
        </div>
      </div>

      <div className="main-footer-content">
        <div className="contact-links">
          <address className="address-links">
            <a href='https://goo.gl/maps/4AT7R9KFAjWhq3oMA' target="_blank" rel="noreferrer" className="address-links__link">GK-0735-5354</a>
            <a href='https://goo.gl/maps/4AT7R9KFAjWhq3oMA' target="_blank" rel="noreferrer" className="address-links__link">ASHONGMAN-ACCRA</a>
            <a href="tel:+233594185609" className="address-links__link">+233 (0)59 418 5609</a>
            <a href="mailto:contact@minohealth.org" className="address-links__link">contact@minohealth.org</a>
          </address>

          <ul className="social-links">
            <li>
              <a href="https://www.instagram.com/minohealth/" target="_blank" rel="noreferrer" className="social-links__link">
                <BsInstagram className="icon" />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/minoHealth/" target="_blank" rel="noreferrer" className="social-links__link">
                <ImFacebook className="icon" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/minohealth" target="_blank" rel="noreferrer" className="social-links__link">
                <BsTwitter className="icon" />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/minohealth/" target="_blank" rel="noreferrer" className="social-links__link">
                <ImLinkedin2 className="icon" />
              </a>
            </li>
          </ul>
        </div>

        <p className="copyright-text">Copyright @ minohealth AI Labs 2023</p>
      </div>
    </footer>
  )
}

export default Footer
