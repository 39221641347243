import { useEffect, useLayoutEffect } from "react";
import { useLoaderData, useOutletContext } from "react-router-dom";
import ServerRedirect from "../../components/routes/ServerRedirect";
import useEventListener from "../../hooks/useEventListener";
import { throttle } from "../../utils/rate-limiters";

import { BsTwitter, BsFacebook, BsLinkedin } from 'react-icons/bs'
import { RiInstagramFill } from 'react-icons/ri'

import "./blog-post.css";

export default function BlogPost() {
  const post = useLoaderData()
  const backgroundHsl = "350 50% 60%"
  const textHsl = "0 0% 0%"
  const { setBlogAccentHsl } = useOutletContext()

  useLayoutEffect(() => {
    setBlogAccentHsl(backgroundHsl, textHsl)
    return () => setBlogAccentHsl(undefined, undefined)
  }, [])

  // function updatePageScrollProgress() {
  //   document.body.style.setProperty(
  //     "--page-scroll-progress", 
  //     window.scrollY / (document.body.scrollHeight - window.innerHeight)
  //   )
  // }

  // useEffect(updatePageScrollProgress, [])
  // useEventListener(window, "scroll", throttle(updatePageScrollProgress, 10))

  if(post === null) {
    return <ServerRedirect to="/404" />
  }

  return (
    <div className="blog-post">
      <article className="blog-post__content">
        <div dangerouslySetInnerHTML={{__html: post.content}}></div>

        <div className="blog-post__content-footer">
          <div style={{display: "flex", alignItems: "center", gap: "0.75rem"}}>
            <p>Published {post.date}</p>
            <nav aria-label="social media" className="blog-post__social-media-nav">
              <ul>
                <li>
                  <a href="https://www.instagram.com/minohealth/" target="_blank" rel="noreferrer"><RiInstagramFill /></a>
                </li>
                <li>
                  <a href="https://www.facebook.com/minoHealth/" target="_blank" rel="noreferrer"><BsFacebook /></a>
                </li>
                <li>
                  <a href="https://twitter.com/minohealth" target="_blank" rel="noreferrer"><BsTwitter /></a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/minohealth/" target="_blank" rel="noreferrer"><BsLinkedin /></a>
                </li>
              </ul>
            </nav>
          </div>

          <p>Have something to say? Email us at <a href="mailto:support@minohealth.org">support@minohealth.org</a></p>
        </div>
      </article>
      {/* <div className="blog-post__progress-indicator"></div> */}
    </div>
  )
}
